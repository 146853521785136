@charset "utf-8";

// Required
@import "../node_modules/bootstrap/functions";
@import "../node_modules/bootstrap/variables";
@import "../node_modules/bootstrap/mixins";

// BS Variable overrides
@import 'bs-overrides';
// Custom fonts
@import 'fonts';

@import "../node_modules/bootstrap/root";
@import "../node_modules/bootstrap/reboot";
@import "../node_modules/bootstrap/type";
@import "../node_modules/bootstrap/images";
@import "../node_modules/bootstrap/code";
@import "../node_modules/bootstrap/grid";
@import "../node_modules/bootstrap/tables";
@import "../node_modules/bootstrap/forms";
@import "../node_modules/bootstrap/buttons";
@import "../node_modules/bootstrap/transitions";
@import "../node_modules/bootstrap/dropdown";
@import "../node_modules/bootstrap/button-group";
@import "../node_modules/bootstrap/input-group";
@import "../node_modules/bootstrap/custom-forms";
@import "../node_modules/bootstrap/nav";
@import "../node_modules/bootstrap/navbar";
@import "../node_modules/bootstrap/card";
@import "../node_modules/bootstrap/breadcrumb";
@import "../node_modules/bootstrap/pagination";
@import "../node_modules/bootstrap/badge";
@import "../node_modules/bootstrap/jumbotron";
@import "../node_modules/bootstrap/alert";
@import "../node_modules/bootstrap/progress";
@import "../node_modules/bootstrap/media";
@import "../node_modules/bootstrap/list-group";
@import "../node_modules/bootstrap/close";
@import "../node_modules/bootstrap/toasts";
@import "../node_modules/bootstrap/modal";
@import "../node_modules/bootstrap/tooltip";
@import "../node_modules/bootstrap/popover";
@import "../node_modules/bootstrap/carousel";
@import "../node_modules/bootstrap/spinners";
@import "../node_modules/bootstrap/utilities";
@import "../node_modules/bootstrap/print";

@import 'variables';

@import 'base';
@import "scrollPosStyles";                //new
@import 'sticky-flex';
@import 'parallax_universal';
@import 'parallax';
//@import 'layout';
//@import 'cards';
//@import 'forms';
@import 'hamburger';

@import 'jquery.smartmenus.bootstrap-4';    //new
@import 'navbar';
@import 'totop';
@import 'footer';
@import 'sitemap';
@import 'cookie';

//@import 'delen';

//@import 'carousel';
@import '../node_modules/ekko-lightbox/ekko-theme';
@import 'custom';
@import 'custom-home';
//@import 'animations';

// custom regels voor dit template only
$header-top-height: 50px;
$header-top-spacing: 10px;
$header-offset-top-xs: 0px;
$header-offset-top-sm: 0px;
$header-offset-top-md: 0px;
$header-offset-top-lg: 0px;


// Affix / Affix-top / Affix-bottom   @todo: affix ombouwen naar scrollPostStyles!
#header {
    &.affix-top {
        top: $header-offset-top-xs;
        transition: top .5s ease 0s;
        @include media-breakpoint-up(md) {
          top: $header-offset-top-sm;
        }
        @include media-breakpoint-up(xl) {
            #navigation ul.navbar-nav {
                margin-top: 25px;
            }
        }
    }
    &.affix {
        top: $header-offset-top-xs - $header-top-height;
        transition: top .5s ease, z-index 0s ease .5s;
        @include media-breakpoint-up(md) {
            top: $header-offset-top-sm - $header-top-height;
            z-index: 1050;
        }
        @include media-breakpoint-up(lg) {
            top: #{$header-offset-top-md - ($header-top-height)};
            #navigation ul.navbar-nav {
                margin-top: 0px;
            }
        }
        @include media-breakpoint-up(xl) {
            top: #{$header-offset-top-lg - ($header-top-height)};
        }
        .navbar-brand {
            margin-top: 4px;
            margin-bottom: 4px;
            img {
                height: 42px;
            }
        }
    }
}
.breadcrumb {
    li:first-child:before {
        content: '';
        margin: 0;
    }
    > li + li::before {
        @include font-size(13px);
        font-weight: 900;
        margin: 0 5px;
        color: theme-color('color-1');
        content: "\00BB";
        list-style: none;
    }
}
.page-options .list-group-item-inline {
    display: inline;
    a {
        color: theme-color('color-2');
        &:hover, &focus {
            color: theme-color('color-1');
        }
        .fa, .fas, .far, .fab {
            height: 30px;
            width: 30px;
            margin: 6px 6px 6px 0;
        }
    }
}
#partnerlist {
  a {
    text-decoration: none;
  }
}

.aside-event .list-unstyled li, .aside-parent .list-unstyled li {
    margin-bottom: 6px;
    .fa, .fas, .far, .fab {
        color: theme-color('color-2');
        float: left;
        clear: left;
        line-height: 1.1;
        vertical-align: top;
    }
}
aside ul.list-unstyled li span {
    margin: 0 10px;
}
.panel-default {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .list-group .list-group-item {
        background-color: #fff;
        border: 1px solid #ddd;
        &.active, &.active:focus, &.active:hover {
            background-color: #fff;
            border: 1px solid #ddd;
            font-weight: bold;
        }
        a {
            text-decoration: none;
        }
    }
    > .panel-heading {
        background-color: theme-color('color-1');
        border-bottom: 1px solid transparent;
        text-transform: uppercase;
        /*font-family: $fontfamily-headings;*/
        font-weight: bold;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        .panel-title {
            font-weight: bold;
        }
        a {
            @include font-size(16px);
            text-decoration: none;
        }
        >a {
            font-size: 18px;
        }
    }
}
.panel-primary {
  >.panel-heading {
    color: #fff;
    background-color: theme-color('color-2');
    border-color: #2e6da4;
    border-radius: 0;
    a {
      display: block;
      @include hover-focus {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
.embed-responsive {
    margin-bottom: 15px;
}
.blok-gradient {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 16px;
  background: #ACC55B; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, #ACC55B, #3A8359); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, #ACC55B, #3A8359); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, #ACC55B, #3A8359); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #ACC55B, #3A8359); /* Standard syntax */
  text-align: center;
  h2,h3,h4,.h2,.h3,.h4 {
      color: #fff;
      text-transform: uppercase;
  }
}
a[href$= ".pdf"]:before {
    background-image: image('icon-pdf.png');
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
