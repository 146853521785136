/* SFEER HOME */
.page-header, .page-header-home {
  position: relative;
  background-color: #003E7E;
  height: 200px;
  background-size: cover;
  background-position: center center;
  margin: 0px 0 20px;
}
.page-header {
  .container .row {
    position: relative;
    top: 100px;
  }
  @media (min-width: 992px) {
    height: 300px;
  }
}
.page-header-home {
  @media (min-width: 992px) {
    height: 219px;
  }
}

.asideleftpos1 ul li a {
  display: block;
  padding: 20px 10px 10px 60px;
  text-decoration: none;
  color: #003E7E;
  font-weight: 400;
  @media (min-width: 1200px) {
    font-size: 20px;
  }
}

ul.submenu.product {
  li {
    border-bottom: 1px solid #fbcbc8;
    background-image: url("../img/spritemenu-home.png");
    background-repeat: no-repeat;
    background-position: 10px 8px;
    &.type2 {
      background: url("../img/spritemenu-home.png") 10px -39px no-repeat;
    }
    &.type3 {
      background: url("../img/spritemenu-home.png") 10px -90px no-repeat;
    }
    &.type0 {
      background: url("../img/spritemenu-home.png") 10px -145px no-repeat;
      border: none;
    }
    a:hover, a.active {
      color: #EE3124;
    }
  }
}

/// aside-block
.aside-block {
  border: 0;
  border-radius: 0;
  display: block;
  background-color: #86aa12;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  margin-top: 30px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-right: 0px;
  padding-bottom: 6px;
  padding-left: 0px;
  font-size: 12px;

  .panel-heading {
    border-bottom: 0;
    margin-top: 0px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .panel-body {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  form {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h3 {
    color: #fff;
    text-align: left;
    font-weight: normal;
    font-weight: 500;
    /*    text-transform: uppercase;*/
  }

  .btn {
    white-space: normal;
    border-radius: 0;
  }

  ul li {
    list-style-type: none;
    color: #FFF;
  }

  ul li a, a {
    color: #FFF;
    text-decoration: none;
    font-size: 13px;
    text-align: left;
  }

  > ul > li a:hover, > ul > li a:focus, a:hover, a:focus {
    background-color: #A6CF16;
    text-decoration: underline;
    color: #000;
  }

  time {
    display: block;
    color: #000;
    font-size: 11px;
  }

  .btn-primary {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.panel-heading {
    padding: 10px 15px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-body {
    padding: 15px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}



/* Linker Aside 3-pos onze voordelen */
.panel-default, .panel-body, .panel-heading {
  border-color: #003e7e;
}

.panel-default > .panel-heading {
  background-color: #003e7e;
  color: #FFF;
}

.vorteile {
  margin-top: 1em;
}

.vort li {
    list-style: inside url(../img/oke.jpg);
    margin-left: -40px;
    margin-top: 10px;
}