.parallax__container {
	clip: rect(0, auto, auto, 0);
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -100;

	.parallax {
		position: fixed;
		width: 100%;
		top: 0;
        left: 0;  // IE-fix : moet naar links
		transform: translate3d(0,0,0);
		transform-style: preserve-3d;
		/* can be put in a seperate class for better control */
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
