$fa-font-path: '../fonts/font-awesome';
@import '../node_modules/font-awesome/fontawesome';
@import '../node_modules/font-awesome/regular';
@import '../node_modules/font-awesome/solid';
@import '../node_modules/font-awesome/brands';

//@todo: fonts naar template.xml
/*@import url('https://fonts.googleapis.com/css?family=Rokkitt:400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Rokkitt:400,700');*/

///@font-face {
///  font-family: 'Poppins';
///  font-weight: 400;
///  font-style: normal;
///  src: font('Poppins-regular/Poppins-regular.eot');
///  src: font('Poppins-regular/Poppins-regular.eot?#iefix') format('embedded-opentype'),
///       local('Poppins'),
///       local('Poppins-regular'),
///       font('Poppins-regular/Poppins-regular.woff2') format('woff2'),
///       font('Poppins-regular/Poppins-regular.woff') format('woff'),
///       font('Poppins-regular/Poppins-regular.ttf') format('truetype'),
///       font('Poppins-regular/Poppins-regular.svg#Poppins') format('svg');
///}
///@font-face {
///  font-family: 'Poppins';
///  font-weight: 700;
///  font-style: normal;
///  src: font('Poppins-700/Poppins-700.eot');
///  src: font('Poppins-700/Poppins-700.eot?#iefix') format('embedded-opentype'),
///       local('Poppins Bold'),
///       local('Poppins-700'),
///       font('Poppins-700/Poppins-700.woff2') format('woff2'),
///       font('Poppins-700/Poppins-700.woff') format('woff'),
///       font('Poppins-700/Poppins-700.ttf') format('truetype'),
///       font('Poppins-700/Poppins-700.svg#Poppins') format('svg');
///}
