button.to-top {
    position: fixed;
    height: 2rem;
    width: 2rem;
    padding: 0;
    bottom: 1em;
    right: 1em;
    visibility: visible;
    z-index: 100;
    border: none;
    transition: transform 1s ease, visibility 1s ease;
    .glyphicon, .fa, .fas, .far, .fab {
        line-height: inherit;
    }
}
.sps.sps--abv .to-top {
    visibility: hidden;
    transform: scale(0);
    transition: transform 1s ease, visibility 0s ease 1s;
}
