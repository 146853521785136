/*
 You probably do not need to edit this at all.

 Add some SmartMenus required styles not covered in Bootstrap 4's default CSS.
 These are theme independent and should work with any Bootstrap 4 theme mod.
*/


/* Carets in collapsible mode (make them look like +/- buttons) */
.navbar-nav.sm-collapsible .sub-arrow {
	position: absolute;
	top: 50%;
	right: 0;
	margin: -0.7em 0.5em 0 0;
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: .25rem;
	padding: 0;
	width: 2em;
	height: 1.4em;
	font-size: 1.25rem;
	line-height: 1.2em;
	text-align: center;
}
.navbar-nav.sm-collapsible .sub-arrow::before {
	content: '+';
}
.navbar-nav.sm-collapsible .show > a > .sub-arrow::before {
	content: '-';
}
.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow {
	border-color: rgba(255, 255, 255, .1);
}
/* make sure there's room for the carets */
.navbar-nav.sm-collapsible .has-submenu {
	padding-right: 3em;
}
/* keep the carets properly positioned */
.navbar-nav.sm-collapsible .nav-link,
.navbar-nav.sm-collapsible .dropdown-item {
	position: relative;
}


/* Nav carets in expanded mode */
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .255em;
	vertical-align: .255em;
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-left: .3em solid transparent;
}
/* point the arrows up for .fixed-bottom navbars */
.fixed-bottom .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow,
.fixed-bottom .navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-toggle::after {
	border-top: 0;
	border-bottom: .3em solid;
}


/* Dropdown carets in expanded mode */
.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	right: 0;
	width: 0;
	height: 0;
	margin-top: -.3em;
	margin-right: 1em;
	border-top: .3em solid transparent;
	border-bottom: .3em solid transparent;
	border-left: .3em solid;
}
/* make sure there's room for the carets */
.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
	padding-right: 2em;
}


/* Scrolling arrows for tall menus */
.navbar-nav .scroll-up,
.navbar-nav .scroll-down {
	position: absolute;
	display: none;
	visibility: hidden;
	height: 20px;
	overflow: hidden;
	text-align: center;
}
.navbar-nav .scroll-up-arrow,
.navbar-nav .scroll-down-arrow {
	position: absolute;
	top: -2px;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	overflow: hidden;
	border-top: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid;
	border-left: 7px solid transparent;
}
.navbar-nav .scroll-down-arrow {
	top: 6px;
	border-top: 7px solid;
	border-right: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 7px solid transparent;
}


/* Add some spacing for 2+ level sub menus in collapsible mode */
.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
	margin: .5em;
}


/* Fix SmartMenus sub menus auto width (subMenusMinWidth/subMenusMaxWidth options) */
.navbar-nav:not([data-sm-skip]) .dropdown-item {
	white-space: normal;
}
.navbar-nav:not(.sm-collapsible) .sm-nowrap > li > .dropdown-item {
	white-space: nowrap;
}