.navbar-light {
  a {
    font-weight: 700;
  }
}
#navbar-balk {
  height: 50px;
  background-color: theme-color('color-6');
  border-bottom: 1px solid #e7e7e7;
  overflow: hidden;
  .container {
    height: 100%;
  }
  a {
    text-decoration: none;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    .nav-kontakt {
      margin-right: 50px;
    }
  }
  .nav-kontakt {
    .fa, .fas, .far {
      color: theme-color('color-1');
      padding-left: 5px;
      padding-right: 5px;
    }
    a {
      @include hover-focus {
        text-decoration: none;
        .fa, .fas, .far {
          color: inherit;
        }
      }
    }
  }
  .nav-kontakt {
    @include font-size(14px);
  }
}
#navbar-main .container ul.navbar-nav {
    transition: margin .5s ease;
}

/*.navbar-nav {
    font-family: $fontfamily-menu;
}*/
#navbar-main > .container {
  position: relative;
}
.input-group-btn .glyphicon {
    top: 0;
}
#zoekform {
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
        position: absolute;
        right: 15px;
        top: -49px;
    }
    .ig-zoeken input[type="text"] {
        padding: 6px 12px;
        height: 48px;
        border: 1px solid;
        border-color: #e7e7e7;
        border-radius: 0;
        background-color: #fff;
        transition: width .5s ease;
        @include media-breakpoint-up(lg) {
            width: 0px;
            padding: 6px 0px;
        }
    }
    &:hover .ig-zoeken input[type="text"] {
        @include media-breakpoint-up(lg) {
            width: 250px;
            padding: 6px 12px;
        }
    }
    .btn-search {
        height: 48px;
        width: 50px;
        border: none;
        border-radius: 0;
        color: theme-color('color-2');
        background-color: #fafafa;
        @include font-size(20px);
    }
}
.navbar-form .input-group .input-group-btn {
    width: 1%;
    @include media-breakpoint-up(lg) {
        width: auto;
    }
}

.navbar-nav .dropdown-menu {
    @include font-size(16px);
    @include media-breakpoint-up(xl) {
      @include font-size(17px);
    }
}
.navbar-nav .open .dropdown-menu {
    background-color: #fff;
}
.dropdown-menu>li>a {
    padding: 5px 20px;
    text-decoration: none;
}
.navbar {
  .navbar-nav {
    .sub-arrow {
      border: none;
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      .nav-item {
        flex: 1 0 auto;
        text-align: center;
      }
      .dropdown {
        .sub-arrow {
          width: 0.5em;
          height: 0.5em;
          font-size: 1rem;
          border-top: 1px solid;
          border-right: 1px solid;
          border-bottom: 0;
          border-left: 0;
          transform: translateY(0em) rotate(135deg);
          transition: all .25s ease-in-out;
        }
        &.show .sub-arrow {
          transform: translateY(0.25em) rotate(-45deg);
        }
        .dropdown-menu {
          li .sub-arrow {
            transform: translateY(0em) rotate(45deg);
          }
        }
      }
    }
    &:not(.sm-collapsible) .nav-link .sub-arrow {
      margin-left: .6em;
    }
  }
}

/// Navbar animation
@include media-breakpoint-up(lg) {
  /* prevent text rendering degrade during animation in Mac browsers */
  #navbarSupportedContent {
    -webkit-font-smoothing: subpixel-antialiased;
    ul.show-animation li {
      /* show animation */
      animation-timing-function: ease-out;
      animation: show-animation 0.25s forwards;
    }
    ul.hide-animation li {
      /* hide animation */
      animation-timing-function: ease-in;
      animation: hide-animation 0.2s forwards;
    }
  }
  @keyframes show-animation {
    0% {
      height: 0;
      overflow: hidden;
    }
//    60% {
//      transform: scale3d(1.05, 1.05, 1);
//    }
    100% {
      height: 2rem;
      overflow: hidden;
    }
  }
  @keyframes hide-animation {
    0% {
      height: 2rem;
      overflow: hidden;
    }
    100% {
      height: 0;
      overflow: hidden;
    }
  }
}
