html {
  height: 100%;
  margin: 0; /* to avoid scrollbars */
  padding: 0;  /* to avoid scrollbars */
}
body {  //, #flex-wrapper {
  display: flex;          /* use the flex model */
  flex-direction: column; /* learn more: http://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/ */
  height: 100%;           /* avoid IE 10-11 min-height bug, Use `%` instead of `vh` since `vh` is buggy in older mobile Safari. */
  #flex-wrapper {
    flex: 1 0 auto;
  }
  footer {
    flex: 0 0 auto;
  }
}
