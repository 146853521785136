// Hierin geen id/class elementen! Dit is puur bedoeld om de basis style van de elementen te initialiseren!
body {
    padding: 0;
    margin: 0;
}
a {
    color: $default-link-color;
    text-decoration: underline;
}
a:active, a:hover {
    color: red;
    text-decoration: none;
}
article a {
    color: $article-link-color;
}
abbr[data-original-title], abbr[title] {
    border-bottom: none;
}