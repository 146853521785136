.bg-parallax {
    min-height: 350px;
    position: relative;
    @include media-breakpoint-up(md) {
        min-height: 400px;
    }
    &.bg-parallax-home {
      min-height: 350px;
      > div {
        min-height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white;
        text-shadow: 2px 2px 2px $black;
      }
      @include media-breakpoint-up(md) {
          min-height: 450px;
      }
    }
    &.sfeer {
      color: $white;
      text-shadow: 6px 6px 2px #000;
      h1, .h1 {
        font-size: 2rem;
        color: $white;
        @include media-breakpoint-up(sm) {
          font-size: 3rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 4rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 4.5rem;
        }
      }
    }
    &.parallax-trans {
      .container {
        background-color: rgba(255,255,255,.9);
        color: #000;
        padding: 2rem;
      }
    }
    > .container-parallax, > div {
      min-height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    video {
      object-fit: cover;
    }
}
