//$navbar-offset-top-blw:  - ( #{$logo-height-md} + 10px + 8px );
$navbar-offset-top-blw:  0 - ( $navbar-height-lg - $navbar-height-xs );

/// Alle styling dat te maken heeft met ScrollPosStyler script
/*@keyframes shift-top {
  0% {
    top: $navbar-offset-top-blw - 50px;///-116px;  /// >= height of total (collapsed) header (header moet hoger staan dan bij 100%, dus bv 50px erboven)
  }
  100% {
    top:  $navbar-offset-top-blw; /// topafwijking voor position:fixed bij sps--blw
  }
}*/
@keyframes shift-top {
  0% {
    top: 0 - $navbar-height-lg;///-116px;  /// >= height of total (collapsed) header (header moet hoger staan dan bij 100%, dus bv 50px erboven)
  }
  100% {
    top: 0 - $bar-height; /// topafwijking voor position:fixed bij sps--blw
  }
}

.sps {
  .navbar {
///    box-shadow: 0px 1px 5px 0px #000;
//    filter: drop-shadow(0px 1px 5px rgba(0,0,0,0.8));
    position: fixed;
    top: 0px;
    transition: all 0.25s ease;
    flex-wrap: nowrap;
    width: 100%;
    z-index: 1;
    .navbar-nav {
      max-height: 90vh;
      overflow-y: auto;
    }
    .nav-link {
      text-decoration: none;
      text-transform: uppercase;
    }
    .navbar-brand {
      transition: all .4s ease;
      height: auto;
/*      margin-top: 5px;
      margin-bottom: 5px;*/
      margin-right: 15px;
      margin-left: 0px;
      z-index: 1;
      img {
        transition: all .4s ease;
        height: $logo-height-xs;
        width: auto;
      }
    }
    @include media-breakpoint-up(sm) {
      .navbar-brand {
        img {
          height: $logo-height-sm;
        }
      }
    }
    @include media-breakpoint-up(md) {
      .navbar-brand {
        img {
          height: $logo-height-md;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      /*position: relative;*/
      flex: 0 0 auto;
      box-shadow: none;
      .navbar-nav {
        overflow-y: visible;
      }
      .navbar-brand {
        margin-top: 10px;
        margin-bottom: 10px;
        img {
          height: $logo-height-lg;
        }
      }
/*      .navbar-brand {
        height: $logo-height-lg;
        img {
          height: $logo-height-lg;
        }
      }*/
    }
    @include media-breakpoint-up(xl) {
      .navbar-brand {
        img {
          height: $logo-height-xl;
        }
      }
    }
  }
  #content {
    margin-top: $navbar-height-xs;
    @include media-breakpoint-up(sm) {
      margin-top: $navbar-height-sm;
    }
    @include media-breakpoint-up(md) {
      margin-top: $navbar-height-md;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $navbar-height-lg;
    }
    @include media-breakpoint-up(xl) {
      margin-top: $navbar-height-xl;
    }
  }
  &.sps--blw {
/*    .navbar-brand img {
      height: $logo-height-xs;
    }*/
    .navbar-brand {
      transition: all .4s ease;
      height: auto;
      z-index: 1;
      img {
        height: $logo-height-xs;
        width: auto;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
//    @include media-breakpoint-up(lg) {
      .navbar {
        position: fixed;
        /*animation: shift-top .5s linear .5s 1 normal backwards;*/
        top: -#{$bar-height}; //$navbar-offset-top-blw;
        right: 0;
        left: 0;
        z-index: 1030;
        box-shadow: 0px 1px 5px 0px #000;
        border-top: none;
        border-bottom: none;
      }
//    }
    ///uitbreiding voor sticky-flex zodat #flex-content niet verspringt bij de overgang van sps--abv en sps--blw
/*    &.sfeer #flex-content, #flex-content {
      @include media-breakpoint-up(lg) {
        margin-top: $navbar-height-lg;
      }
      @include media-breakpoint-up(xl) {
        margin-top: $navbar-height-xl;
      }
    }*/
/*    #content {
      margin-top: $navbar-height-xs;
      @include media-breakpoint-up(sm) {
        margin-top: $navbar-height-sm;
      }
      @include media-breakpoint-up(md) {
        margin-top: $navbar-height-md;
      }
      @include media-breakpoint-up(lg) {
        margin-top: $navbar-height-lg;
      }
      @include media-breakpoint-up(xl) {
        margin-top: $navbar-height-xl;
      }
    }*/

  }
}
