// BS Variable overrides

///$enable-caret:                                true !default;
$enable-rounded:                              false;
$enable-shadows:                              true;
$enable-gradients:                            true;
///$enable-transitions:                          true !default;
///$enable-prefers-reduced-motion-media-query:   true !default;
///$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
///$enable-grid-classes:                         true !default;
///$enable-pointer-cursor-for-buttons:           true !default;
///$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true;
///$enable-validation-icons:                     true !default;
///$enable-deprecation-messages:                 true !default;


$body-bg: #fff; //#f2f2f2;
$body-color: $black;
$line-height-base: 1.4;

$font-family-base:            'Titillium Web', sans-serif;

//$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 1.6;
$h3-font-size:                $font-size-base * 1.45;
$h4-font-size:                $font-size-base * 1.3;
$h5-font-size:                $font-size-base * 1.2;
//$h6-font-size:                $font-size-base !default;

$theme-colors: (
  "color-1":  #f4681f,
  "color-2":  #ea5b1e,
  "color-3":  #f6690d,
  "color-4":  #434a51,
  "color-5":  #b4ca04,
  "color-6":  #f2f2f2,
  "info":     #17a2b8
/*  "color-7": #f5f5f5,
  "color-8": #262626,*/
);

$link-color:                    theme-color('color-4');
$link-hover-color:              theme-color('color-3');
$link-decoration:               underline;
$link-hover-decoration:         underline;

///navbar
$navbar-padding-x:              0;
$navbar-padding-y:              0;
$nav-link-padding-y:            0.8125rem;
$navbar-light-color:            $black;
$navbar-light-hover-color:      theme-color('color-2');
$navbar-light-active-color:     theme-color('color-2');
$navbar-light-toggler-icon-bg:  none;
$navbar-dark-toggler-icon-bg:   none;
$dropdown-padding-y:            0;
$dropdown-link-hover-bg:        theme-color('color-2');
$dropdown-link-hover-color:     $white;
$dropdown-link-active-bg:       $gray-400;
$dropdown-link-active-color:    theme-color('color-2');

///breadcrumb
$breadcrumb-bg:                 transparent;
$breadcrumb-padding-x:          0;

/// cards
$card-height:                   100%;
$card-inner-border-radius:      0;
$card-spacer-x:                 1rem;

/// Pagination
$pagination-color:        theme-color('color-2');
///$pagination-bg:           $white;
///$pagination-active-color: $white;
$pagination-active-bg:    theme-color('color-2');

///list-group
$list-group-bg:           transparent;
$list-group-active-bg:    theme-color('color-1');
$list-group-active-color: $white;
