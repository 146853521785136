.sitemap li {
  list-style: none!important;
  @include font-size(1.2rem);
  line-height: 1.8;
  &:before {
      font-variant: normal;
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-weight: 400;
//      line-height: 1;
      color: $black;
      content: '\f07b' !important;
      display: inline-block;
      width: 1rem;
      margin-left: -1.5rem;
      margin-right: .5rem;
  }
  li {
    @include font-size(1rem);
    &:before {
        font-variant: normal;
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 400;
//        line-height: 1;
        color: theme-color('color-1');
        content: '\f15b' !important;
    }
    li {
      &:before {
        color: theme-color('color-1');
      }
      a {
        color: $black;
      }
    }
  }
}