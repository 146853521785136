.navbar-toggler {
  width: 40px;
  height: 40px;
  //margin: 50px auto;
  position: relative;
  cursor: pointer;
  background: rgba(255,255,255,.4);
  //border-radius:5px;
  overflow: hidden;
  padding: 0;
  border: 0;
  * {
    transition: .25s ease-in-out;
  }
  :active, :focus {
    border: 0 transparent;
    outline: none;
  }
  span:not(.d-block) {
    display: block;
    background: theme-color('color-1');
    border-radius: 2px;
  }
  &:hover {
    background: rgba(255,255,255,.8);
  }
  #hamburger {
    position:absolute;
    height:100%;
    width:100%;
    top: 0;
    span {
      width:32px;
      height:4px;
      position:relative;
      top: 6px; ///24px;
      left: 0px; ///20px;
      margin: 4px 4px; ///10px 0; //4px en ((40px(navbar-toggler-width) - 32px(span-width)) / 2)
      &:nth-child(1){
        transition-delay:.5s;
      }
      &:nth-child(2){
        transition-delay:.625s;
      }
      &:nth-child(3){
        transition-delay:.75s;
      }
    }
  }
  #cross {
    position:absolute;
    height: 100%;
    width: 100%;
    transform:rotate(45deg);
    top: 0;
    span{
      &:nth-child(1){
        height:0%;
        width:4px;
        position:absolute;
        top: 10%; ///10%;
        left: 18px; ///(40px(navbar-toggler-width) - 4px(width)) / 2;
        transition-delay:0s;
      }
      &:nth-child(2){
        width:0%;
        height:4px;
        position:absolute;
        left: 10%; ///10%;
        top: 18px; ///(40px(navbar-toggler-height) - 4px(height)) / 2;
        transition-delay:.25s;
      }
    }
  }
  &[aria-expanded="true"] {
    #hamburger {
      span {
        width:0%;
        &:nth-child(1){
          transition-delay:0s;
        }
        &:nth-child(2){
          transition-delay:.125s;
        }
        &:nth-child(3){
          transition-delay:.25s;
        }
      }
    }
    #cross {
      span {
        &:nth-child(1){
          height:80%;
          transition-delay:.625s;
        }
      }
      span {
        &:nth-child(2){
          width:80%;
          transition-delay:.375s;
        }
      }
    }
  }
}
