// responsive header heights
$header-height-xs: 101px !default;
$header-height-sm: 101px !default;
$header-height-md: 151px !default;
$header-height-lg: 201px !default;
$header-height-xl: 151px !default;

// responsive grid startpoints
$sm-width-start: 576px;
$md-width-start: 768px;
$lg-width-start: 992px;
$xl-width-start: 1200px;

$logo-height-xs: 36px;
$logo-height-sm: 36px;
$logo-height-md: 36px;
$logo-height-lg: 36px;
$logo-height-xl: 36px;

$bar-height:        50px;
$navbar-height-xs:  96px;
$navbar-height-sm:  96px;
$navbar-height-md:  96px;
$navbar-height-lg:  116px;
$navbar-height-xl:  116px;

// colors
$color1: #f4681f;
$color2: #ea5b1e;
$color3: #f6690d;
$color4: #434a51; ///Voor bv copyright balk

$default-color: #000;
$default-link-color: #002c49;
$article-link-color: #da0000;
$header-background-color: #fff;
$header-color: #000;
$navbar-background-color: #ff0;
$navbar-color: #000;
$footer-background-color: theme-color('color-1');
$footer-color: #000;

$social-colors: (
  "facebook": #3b5998,
  "twitter": #00aced,
  "linkedin": #0177b5,
//  "googleplus": #db4437,
  "youtube": #d2222a,
  "email": #2f9a14,
  "pinterest": #b81621,
  "reddit": #ff4500,
  "tumblr": #35465c,
  "pocket": #ef3e56,
  "vk": #45668e
);


// fonts
//$font-size-base: 16px;
//$base-line-height: 1.42857143;
/*$fontfamily-text: 'Open Sans', sans-serif;*/
/*$fontfamily-text:  'Source Sans Pro', sans-serif;
$fontfamily-headings: 'Rokkitt', serif;
$fontfamily-smallheadings: 'Rokkitt', serif;
$fontfamily-menu: 'Rokkitt', serif;*/