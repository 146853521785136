.mapleft {
  width: 100%;
  max-height: 100%;
  height: 380px;
  margin-right: 15px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    width: 50%;
    float: left;
  }
}
#map {
  width: 100%;
  height: 100%;
}
