@import 'ekko-lightbox-5.3.0';

a[data-toggle="lightbox"] {
  display: block;
  /*overflow: hidden;*/
  img {
    transition: all .6s ease;
  }
  &:hover, &:focus {
/*    img {
      transform: scale(1.1);
    }*/
  }
}
.ekko-lightbox {
  .ekko-lightbox-container {
    overflow: hidden;
  }
  .modal-header {
    position: absolute;
    top: 0;
    width: 100%;
    border-bottom: 0;
    z-index: 2;
    .close {
      position: absolute;
      right: 1rem;
      background-color: rgba(255,255,255,1);
      border-radius: 0.3rem;
      opacity: 1;
      line-height: 1ex;
    }
  }
  .modal-footer {
    position: relative;
    bottom: 0;
    padding: 0 1rem 1rem;
    display: flex;
    background-color: rgba(255,255,255,0.6);
    border-radius: 0.3rem;
    border-color: #fff;
    max-width: 100%;
    justify-content: center;
    font-weight: 700;
    text-align: center;
    @include media-breakpoint-up(sm) {
      position: fixed;
      bottom: 1rem;
      left: 1rem;
      right: 1rem;
      padding: 1rem;
      max-width: calc(100% - 2rem);
    }
  }
  .ekko-lightbox-nav-overlay a {
    color: #fff;
    span {
      text-shadow: 2px 2px 2px #000;
    }
    &:hover span, &:focus span {
      text-shadow: 1px 1px 1px #000;
    }
  }
}