#footer {
  background-color: $footer-background-color;
  color: $footer-color;
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}
#footer ul {
  padding-left: 0;
}
#footer li {
  list-style: none;
}
#footer a {
  color: $footer-color;
  text-decoration: underline;
  .fa, .fas, .far, .fab {
    margin: 0 10px 0 0;
  }
  @include hover-focus {
    text-decoration: none;
  }
}
#footer {
  h2,h3,.h2,.h3 {
    text-transform: uppercase;
  }
  .footercol {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footerfigure {
    display: inline-block;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
  ul.social {
    overflow: auto;
    li {
      display: inline-block;
      list-style-type: none;
      margin-bottom: 10px;
      a {
        .fa, .fas, .far, .fab {
          background: #205D7A;
          color: #fff;
          width: 40px;
          height: 40px;
          border-radius: 0px;
          @include font-size(26px);
          text-align: center;
          margin-right: 10px;
          padding-top: 15%;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: .7;
          }
          &.fa-facebook {
            background:#3b5998;
          }
          &.fa-linkedin {
            background:#007bb6;
          }
          &.fa-twitter {
            background:#00aced;
          }
          &.fa-rss {
            background:#ff9900;
          }
          &.google-plus {
            background:#dd4b39;
          }
        }
      }
    }
  }
}
/*#footer .copyright {
  background-color: theme-color('color-2');
  color: $white;
  padding: 10px 0;
  a {
    color: $white;
  }
}*/

footer {
  color: #000;
  h2, h3, p, a {
    color: #000;
  }
  .footercol {
    margin-bottom: 15px;
  }
  .doormat {
    font-size: 13px;
    color: #000;
    padding: 20px 0 30px 0;
    background-color: #dedfe0;
    @media (min-width: 992px) {
        background-image: url("../img/footer-bg.png");
        background-position: center top;
        background-repeat: no-repeat;
    }
  }
  .realfooter {
    background-color: #434a51;
    font-weight: 400;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 2em;
    color: #fff;
    text-align: left;
    padding: 10px 0 10px 0;
    @media (min-width: 768px) {
        font-size: 14px;
        font-size: 0.875rem;
    }
    @media (min-width: 992px) {
        font-size: 16px;
        font-size: 1rem;
    }
    a {
      font-weight: 400;
      color: #fff;
      text-decoration: none;
      @include hover-focus {
        text-decoration: underline;
      }
    }
    .copyright {
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
    }
    .socialicons {
      text-align: center;
      @media (min-width: 768px) {
          text-align: right;
      }
      .fa-stack {
          color: #000;
      }
      a {
        margin-left: 10px;
        @include hover-focus-active {
          .fa-stack .fa-inverse {
            color: #f4681f;
          }
        }
      }
      img {
        vertical-align: middle;
        height: 35px;
        width: auto;
      }
    }
  }
  h3, h3 a {
    font-weight: normal;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1em;
    margin-left: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  ul li a {
    font-size: 13px;
  }
  address p {
    font-size: 13px;
  }
}
/* Derde kolom van footer h3 iets anders */
.ftcolh3 {
  /*background-color: #86aa12;*/
  background-color: #f6690d;
  color: #fff;
  font-size: 13px;
  padding: 4px 6px 4px 6px;
  font-variant: normal;
  text-transform: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

